<template>
  <div>
    <button @click.prevent="openNewClinicModal" class="btn btn-primary mb-4">
      <i class="bi bi-plus-lg me-1 mb-1"></i>Add Clinic
    </button>
    <NewClinic
      name="new-clinic-modal"
      v-model="isNewClinicModalOpen"
      @cancel="closeNewClinicModal"
      @confirm="onClinicCreated"
    >
      <template v-slot:title>New clinic</template>
    </NewClinic>
    <!--begin::Table-->
    <vue-good-table
      mode="remote"
      :total-rows="totalRecords"
      :is-loading="isTableLoading"
      :pagination-options="{ enabled: true }"
      :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
      :rows="rows"
      :columns="columns"
      :fixed-header="true"
      @page-change="onPageChange"
      @sort-change="onSortChange"
      @column-filter="onColumnFilter"
      @per-page-change="onPerPageChange"
      @row-click="toClinic"
      styleClass="custom-table clickable"
    >
      <template #loading-content>Loading...</template>
      <template #emptystate>No entry found</template>
      <template #table-row="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'appearOnSite'">
          <span class="badge" :class="`badge-light-${ props.row.appearOnSite ? 'success' : 'danger' }`">{{
           props.row.appearOnSite ? "Yes" : "No"
          }}</span>
        </span>

        <span v-else-if="props.column.field === 'presentationDraft.approved'">
          <span class="badge" :class="`badge-light-${ props.row.presentationDraft.approved ? 'success' : 'danger' }`">{{
           props.row.presentationDraft.approved ? "No" : "Yes"
          }}</span>
        </span>

        <span v-else-if="props.column.field === 'prospectsTransmission'">
          <p>
            Transmitted prospects: {{props.row.currMonthTransmittedProspects}}
          </p>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template #pagination-bottom="props">
        <div
          class="d-flex justify-content-between flex-wrap align-items-center"
        >
          <div class="d-flex align-items-center mb-0 mt-1">
            <select
              class="form-control form-control-sm me-3"
              :value="serverParams.perPage"
              @change="
                (e) => props.perPageChanged({ currentPerPage: e.target.value })
              "
            >
              <option
                v-for="(option, idx) in ['5', '10', '25', '50', '100']"
                :key="`select-perpage-${idx}`"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
            <span class="text-nowrap">clinics per page </span>
          </div>
          <div>
            <ul class="pagination">
              <li
                class="page-item previous"
                :class="{ disabled: serverParams.page === 1 }"
              >
                <a
                  @click.prevent="
                    props.pageChanged({ currentPage: serverParams.page - 1 })
                  "
                  href="#"
                  class="page-link"
                  ><i class="previous"></i
                ></a>
              </li>
              <div
                v-for="idx in Math.ceil(props.total / serverParams.perPage)"
                class="d-flex flex-row align-items-center"
                :key="idx"
              >
                <li
                  class="page-item"
                  :class="{ active: serverParams.page === idx }"
                >
                  <a
                    @click.prevent="props.pageChanged({ currentPage: idx })"
                    href="#"
                    class="page-link"
                    v-if="
                      idx <= 3 ||
                      (idx >= serverParams.page - 1 &&
                        idx <= serverParams.page + 1) ||
                      idx >= Math.ceil(props.total / serverParams.perPage) - 2
                    "
                    >{{ idx }}</a
                  >
                </li>
                <p
                  class="mb-0 mt-1 mx-3"
                  v-if="
                    (idx === serverParams.page - 2 && idx > 3) ||
                    (idx === serverParams.page + 2 &&
                      idx < Math.ceil(props.total / serverParams.perPage) - 2)
                  "
                >
                  ...
                </p>
              </div>
              <li
                class="page-item next"
                :class="{
                  disabled:
                    serverParams.page ===
                    Math.ceil(props.total / serverParams.perPage),
                }"
              >
                <a
                  @click.prevent="
                    props.pageChanged({ currentPage: serverParams.page + 1 })
                  "
                  href="#"
                  class="page-link"
                  ><i class="next"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!--end::Table-->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import NewClinic from "@/components/modals/clinics/NewClinic.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default {
  components: {
    NewClinic,
  },
  data() {
    return {
      isNewClinicModalOpen: false,
      isTableLoading: false,
      totalRecords: 0,
      columns: [
        {
          label: "Name",
          field: "name",
          sortable: true,
          width: "10%",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "City",
          field: (rowObject) => {
            return rowObject.addresses.find(
              (clinic) => clinic.label === "DEFAULT"
            ).city;
          },
          sortable: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Country",
          field: (rowObject) => {
            return rowObject.addresses.find(
              (clinic) => clinic.label === "DEFAULT"
            ).country;
          },
          sortable: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "On website",
          field: "appearOnSite",
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filter: true,
            filterDropdownItems: [
              { value: true, text: "Yes" },
              { value: false, text: "No" },
            ],
          },
        },
        {
          label: "Presentation pending review",
          field: "presentationDraft.approved",
          width: "15%",
        },
        {
          label: "Account type",
          field: "accountType",
          width: "10%",
        },
        {
          label: "Prospects transmission (current month)",
          field: "prospectsTransmission",
          width: "20%",
        },
        {
          label: "Created",
          field: "createdAt",
          sortable: true,
          formatFn: (dt) => new Date(dt).toLocaleDateString(),
        },
      ],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "updatedAt",
          type: "desc",
        },
        page: 1,
        perPage: 25,
      },
    };
  },
  mounted() {
    this.fetchItemsFromApi();
    setCurrentPageBreadcrumbs("Clinics", []);
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      console.log("onPageChange", params);
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const tableSearch = {};
      console.log(this.serverParams.columnFilters);
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        if (["appearOnSite"].includes(e)) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      const getApiParams = {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
      };

      ApiService.query("/clinics", { params: getApiParams })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
    openNewClinicModal() {
      this.$vfm.show("new-clinic-modal");
    },
    closeNewClinicModal() {
      this.isNewClinicModalOpen = false;
    },
    onClinicCreated(createdClinic) {
      this.$vfm.hide("new-clinic-modal");
      this.$router.push({ name: "clinic", params: { id: createdClinic.id } });
    },
    toClinic(params) {
      this.$router.push(`/clinics/${params.row.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.operations-table {
  background-color: #fff;
}
</style>